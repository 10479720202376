@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    font-size: 15px;
}

body {
    margin: 0;
    padding: 0;
    background-color: #f2f2f2;
}

* {
    scrollbar-color: #525252 #fff;
    scrollbar-width: auto;
}

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: thin;
        /* Firefox */
    }
}

/* font: -------------------------------------------------------------------- */
@font-face {
    font-family: "JetBrainsMono";
    src: url("../public/fonts/Abel/Abel-Regular.ttf");
    font-style: normal;
}
